/* Loader starts------------- */

.initial-loader {
	background: #000000;
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 99999;
}
.initial-loader-inner {
	bottom: 0;
	height: 45px;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
	text-align: center;
}

.initial-loader img {
	height: 45px;
}

@-moz-keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}
/* IE */
@-ms-keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}
/* Opera and prob css3 final iteration */
@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}
.blink-image {
	-moz-animation: blink normal 2s infinite ease-in-out; /* Firefox */
	-webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */
	-ms-animation: blink normal 2s infinite ease-in-out; /* IE */
	animation: blink normal 2s infinite ease-in-out; /* Opera and prob css3 final iteration */
}
/* Loader ends-------------  */
