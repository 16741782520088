.group {
	position: relative;
	display: inline-block;
	line-height: 16px;
	margin: 25vh auto;
	text-align: center;
}

.bigSqr {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 40px;
	overflow: hidden;
	transform-origin: bottom left;
	-webkit-animation: bigSqrShrink 1s linear infinite;
	animation: bigSqrShrink 1s linear infinite;
}

.square {
	position: absolute;
	width: 20px;
	height: 20px;
	background: #2c5285;
}

.first {
	left: 0px;
	top: 20px;
	// border-radius: 0 5px 0 20px;
}

.second {
	left: 20px;
	top: 20px;
	-webkit-animation: drop2 1s linear infinite;
	animation: drop2 1s linear infinite;
	background: #95c14e;
	// border-radius: 5px 0 20px 0;
}

.third {
	left: 0px;
	top: 0px;
	-webkit-animation: drop3 1s linear infinite;
	animation: drop3 1s linear infinite;
	// border-radius: 20px 0 5px 0;
}

.fourth {
	left: 20px;
	top: 0px;
	-webkit-animation: drop4 1s linear infinite;
	animation: drop4 1s linear infinite;
	// border-radius:0 20px 0 5px;
}

.text {
	line-height: 16px;
	font-weight: 600;
	color: #2c5285;
	display: block;
	margin: 10px auto;
	padding: 3px;
	font-size: 16px;
}
.text-quote {
	color: #78838a;
}

@-webkit-keyframes bigSqrShrink {
	0% {
		transform: scale(1);
	}
	90% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.5);
	}
}

@keyframes bigSqrShrink {
	0% {
		transform: scale(1);
	}
	90% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.5);
	}
}
@-webkit-keyframes drop2 {
	0% {
		transform: translateY(-50px);
	}
	25% {
		transform: translate(0);
	}
	100% {
		transform: translate(0);
	}
}
@keyframes drop2 {
	0% {
		transform: translateY(-50px);
	}
	25% {
		transform: translate(0);
	}
	100% {
		transform: translate(0);
	}
}
@-webkit-keyframes drop3 {
	0% {
		transform: translateY(-50px);
	}
	50% {
		transform: translate(0);
	}
	100% {
		transform: translate(0);
	}
}
@keyframes drop3 {
	0% {
		transform: translateY(-50px);
	}
	50% {
		transform: translate(0);
	}
	100% {
		transform: translate(0);
	}
}
@-webkit-keyframes drop4 {
	0% {
		transform: translateY(-50px);
	}
	75% {
		transform: translate(0);
	}
	100% {
		transform: translate(0);
	}
}
@keyframes drop4 {
	0% {
		transform: translateY(-50px);
	}
	75% {
		transform: translate(0);
	}
	100% {
		transform: translate(0);
	}
}
